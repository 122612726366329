import { useEffect, useRef } from 'react';
import './App.css';
import ScreenShotCard from './ScreenShotCard';

function App() {
  const link = 'https://telegram.me/+eW1G7akKciViMTI1'
  const name = 'Diva Trading'
  const buttonRef = useRef(null);

  // useEffect(() => {

  //   const timer = setTimeout(() => {
  //     if (buttonRef.current) {
  //       buttonRef.current.click();
  //     }
  //   }, 6000); // 4000 milliseconds = 4 seconds

  //   return () => clearTimeout(timer); // Cleanup on unmount
  // }, []);

  const handleClick = () => {
    window.location.href = link;
  };
  return (
    <div className='flex max-h-screen    relative h-full w-full flex-col items-center justify-start bg-black '>
      {/* absolute */}
      {/* Bg */}
      <div className="fixed top-0 left-0 right-0 bottom-0 bg-black">
        <img
          src="/logo.webp"
          alt="background"
          className="w-full h-screen   overflow-hidden md:object-contain object-cover blur-md items-start -z-10 sm:opacity-80 opacity-70"
        />
      </div>

      {/* Main Container */}
      <div className="w-full h-full text-white overflow-hidden relative z-10 max-w-[100rem]">
        {/* Hero Section */}
        <section className="flex overflow-hidden lg:flex-row flex-col sm:gap-20 gap-10  md:mt-6 mt-4 px-2">
          {/* Left Container */}
          <div className="flex overflow-hidden  justify-center items-center flex-1">
            {/* box */}
            <div className="sm:bg-black/30  max-w-[33rem] text-center flex flex-col items-center justify-center md:p-8 p-6 rounded-2xl ">
              {/* Logo */}
              <a href={link} target='_blank' className="">
                <img
                  src="/logo.webp"
                  alt="logo"
                  className="w-40    "
                /></a>
              {/* Text */}
              <h1 className="lg:text-6xl text-5xl mt-3 md:leading-[3.4rem] leading-[3rem] font-baba  font-bold flex flex-col items-center justify-center ">CLICK THE <span className="">BUTTON BELOW</span></h1>
              <p className="text-white/70 leading-5  font-baba text-xl tracking-wider font-normal   max-w-96 mt-4">and  receive your immediate access to my Free Telegram Group!</p>
              <button
                ref={buttonRef}
                onClick={handleClick}
                className="h-20  group relative font-baba text-2xl tracking-wider hover:scale-105 transition-all animate-bounce hover:animate-none font-semibold w-full mt-8  rounded-md">

                <span className="absolute top-6  left-0 right-0 flex w-fit h-fit mx-auto items-center justify-center gap-2  bottom-0 text-white">
                  <span className="pb-0.5">

                    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.0773 2.00105C13.3972 2.01117 14.702 2.28274 15.9164 2.80012C17.1309 3.31749 18.2311 4.07042 19.1535 5.01549C20.0759 5.96057 20.8023 7.07909 21.2907 8.30655C21.7792 9.53401 22.0201 10.8461 21.9995 12.1672C21.3218 25.3588 2.44397 25.2476 1.99953 12.0115C1.99051 10.6878 2.2456 9.37566 2.74984 8.15196C3.25408 6.92825 3.99732 5.81765 4.93597 4.88527C5.87462 3.95289 6.98978 3.2175 8.21608 2.72223C9.44237 2.22696 10.7551 1.98178 12.0773 2.00105ZM13.8945 10.3765C13.8945 10.3765 13.9106 10.3876 13.7884 10.5433C13.6662 10.699 13.4218 10.9437 13.2995 11.055L10.7551 13.5242C10.2106 14.0692 10.2218 14.4029 10.8551 14.8589C11.6662 15.4373 12.4884 15.9712 13.3218 16.5385C14.1551 17.1057 15.1773 17.9399 15.5995 16.7164C15.7071 16.3897 15.7888 16.055 15.844 15.7154C16.0218 14.7366 16.1995 13.7578 16.3551 12.7679C16.5662 11.3775 16.7662 9.98719 16.944 8.58572C17.0329 7.89611 16.6662 7.58467 15.9773 7.75151C15.693 7.81843 15.4143 7.90768 15.144 8.01846L7.88842 11.0772C7.19953 11.3664 6.51064 11.6778 5.83286 12.0115C5.6662 12.1005 5.45508 12.3452 5.4662 12.512C5.47731 12.6789 5.71064 12.868 5.89953 12.9458C6.3662 13.1349 6.8662 13.2684 7.35508 13.4241C7.72694 13.5516 8.12456 13.5847 8.51236 13.5207C8.90017 13.4566 9.26606 13.2973 9.57731 13.0571C10.4995 12.3897 11.444 11.7668 12.3884 11.144C12.8329 10.8436 13.144 10.6768 13.5773 10.3765C13.7995 10.2653 13.8501 10.2145 13.8945 10.2812V10.3765Z" fill="currentColor" />
                    </svg></span>

                  Join Telegram for Free</span>
                <img
                  src="/gg.webp"
                  alt="background"
                  className="w-full h-20 object-fill   "
                />
                <span className="">

                </span></button>
              <p className="text-white/90 leading-5  font-semibold text-sm max-w-96 mt-4">Don't have Telegram on your cell phone?</p>
              <a href={link} target='_blank' className="text-white/60 leading-5  text-sm max-w-96 mt-1">Click the button below & download</a>
              <div className="flex gap-6  mt-4">
                <a href={link} target='_blank' className="">
                  <img src="/a2.webp" alt="" className='w-36 border-white/40 rounded-md border-2' />
                </a>
                <a href={link} target='_blank' className="">
                  <img src="/a1.webp" alt="" className='w-36 border-white/40 rounded-md border-2' />
                </a>
              </div>
            </div>



          </div>

          {/* Right Container */}
          <div className="flex flex-1 items-center justify-center lg:justify-start">
            {/* <img
              src="/g8.webp"
              alt="background"
              className="w-full h-full  max-w-[30rem] object-contain "
            /> */}
          </div>
        </section>

        {/* Screen Shot Section */}
        {/* <section className="items-center justify-center flex flex-col mt-10 mb-10">
          <h4 className="text-4xl text-center font-semibold font-baba">See the Result of some of our group member!</h4>
          <div className="logos flex  max-w-[100vw]">
            <div className="flex    items-start hide-scroll mt-8 logos-slide ">
              <ScreenShotCard link={link} img="/pp1.webp" />
              <ScreenShotCard link={link} img="/pp2.webp" />
              <ScreenShotCard link={link} img="/pp3.webp" />
              <ScreenShotCard link={link} img="/pp4.webp" />
              <ScreenShotCard link={link} img="/pp5.webp" />
              <ScreenShotCard link={link} img="/pp6.webp" />
              <ScreenShotCard link={link} img="/pp7.webp" />
            </div>
            <div className="flex    items-start hide-scroll mt-8 logos-slide ">
              <ScreenShotCard link={link} img="/pp1.webp" />
              <ScreenShotCard link={link} img="/pp2.webp" />
              <ScreenShotCard link={link} img="/pp3.webp" />
              <ScreenShotCard link={link} img="/pp4.webp" />
              <ScreenShotCard link={link} img="/pp5.webp" />
              <ScreenShotCard link={link} img="/pp6.webp" />
              <ScreenShotCard link={link} img="/pp7.webp" />
            </div>
          </div>
        </section> */}

        {/* Footer */}
        {/* <footer className='w-full  text-white/70 px-3 bg-black text-center items-center  justify-center flex flex-col sm:mt-16 mt-10 py-5 text-sm'>
          <p className="max-w-5xl">© 2024 {name}. All rights reserved
          </p>
          <p className="max-w-5xl">ATTENTION: We don't sell any products through the website.
            <p className="mt-1 max-w-5xl">
              PLEASE NOTE: This site is not affiliated with Facebook or any Facebook entity. Once you leave Facebook, it’s not their responsibility, it’s our website’s. We make every effort to clearly state and show all product proofs and use actual results. We do not sell your email address or any information to third parties. We never do any kind of spam. If you have any questions, please feel free to use the contact our support in telegram during business hours Monday through Friday from 9:00 am to 6:00 pm. We read and respond to all messages on a first-come, first-served basis.</p></p></footer> */}
      </div>
    </div>
  );
}

export default App;
